<template>
  <b-alert show v-if="!user" class="centered">
    <strong>لا يمكن تفييم العقار!</strong><br />
    <router-link :to="{ name: 'login' }"
      >الرجاء تسجيل الدخول لتقييم العقار</router-link
    >
  </b-alert>
  <div v-else>
    <b-alert show v-if="isRated" class="centered"
      ><strong>شكراً لك!</strong> <br />
      لقد قمت بتقييم تجربتك لهذا العقار
    </b-alert>
    <div
      v-else
      id="rating-property"
      :class="{ 'is-rated': !showData, ['value-' + rateValue]: rateValue }"
    >
      <h4>قيم تجربتك لهذا العقار</h4>
      <p class="info-text">أخبرنا أكثر عن تجربتك لهذا العقار</p>
      <form>
        <div class="rating-wrap">
          <rate
            id="experience-rating"
            v-model="rateValue"
            :length="5"
            @after-rate="onAftereRate"
          />
        </div>

        <template v-if="showData">
          <b-form-group class="m-0">
            <b-form-checkbox-group
              v-model="ratingDetails"
              size="lg"
              :options="ratingOptions"
              value-field="_id"
              text-field="body"
              name="types"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
            @hidden="onHidden"
          >
            <b-button
              v-if="rateValue !== null && ratingDetails.length > 0"
              type="submit"
              ref="rate"
              variant="primary mt-4"
              @click.prevent="onSubmit"
              >إرسال التقيم</b-button
            >
          </b-overlay>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
// Config File
import { mapState, mapGetters } from 'vuex'
import { realEstateService } from '../../services/realEstate.service'
//Style
import '@/styles/property/rating-block.scss'

export default {
  props: ['property', 'isRated', 'user'],
  data() {
    return {
      rateValue: null,
      busy: false,
      ratingDetails: [],
      showData: true
    }
  },
  computed: {
    ...mapGetters('accountState', ['getRatingOptions']),
    ...mapState('accountState', {
      status: state => state.status,
      userData: state => state.user
    }),
    ratingOptions() {
      return this.getRatingOptions.filter(ratingOption => {
        return ratingOption.rating === this.rateValue
      })
    },
    isLoggedIn() {
      return this.status.loggedIn
    }
  },

  methods: {
    onAftereRate() {
      this.ratingDetails = []
    },

    onSubmit() {
      let data = {
        realEstate: this.property._id,
        owner: this.property.account.id,
        user: this.user,
        rating: this.rateValue,
        options: this.ratingDetails,
        account: this.userData.accountId
      }

      if (!this.isLoggedIn) {
        return this.$toasted.show('لابد من تسجيل الدخول أولاً', {
          duration: 2000,
          icon: 'hi-login'
        })
      }
      this.rateProperty(data)
    },
    onHidden() {
      // Return focus to the button once hidden
      this.$refs.rate.focus()
    },
    //rate
    rateProperty(data) {
      this.busy = true
      realEstateService
        .rateProperty(data)
        .then(
          () => {
            this.busy = false
            this.$toasted.show('تم إرسال التقييم .. شكراً لك', {
              type: 'success',
              duration: 2000,
              icon: 'hi-check'
            })
            this.showData = false
          },
          error => {
            this.busy = false
            this.$toasted.show(error.message, {
              duration: 2000,
              type: 'error',
              icon: 'hi-close'
            })
          }
        )
        .catch(err => {
          this.busy = false
          this.$toasted.show(err, {
            duration: 2000,
            type: 'error',
            icon: 'hi-close'
          })
        })
    }
  }
}
</script>
<style>
button.Rate__star {
  border: none;
  background: transparent;
}
</style>
